export const FETCH_LIST = "FETCH_LIST";

export const GET = "GET";

export const STORE = "STORE";

export const UPDATE = "UPDATE";

export const DESTROY = "DESTROY";

export const FILTER_BY = "FILTER_BY";

export const LOGIN = "LOGIN";

export const FETCH_AUTHENTICATE_USER = "FETCH_AUTHENTICATE_USER";

export const LOGOUT = "LOGOUT";




