import { render, staticRenderFns } from "./cadre-logique.vue?vue&type=template&id=52c98f3a&scoped=true&"
import script from "./cadre-logique.vue?vue&type=script&lang=js&"
export * from "./cadre-logique.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "52c98f3a",
  null
  
)

export default component.exports