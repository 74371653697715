//const API_BASE_URL = "http://192.168.8.111:8001"
const API_BASE_URL = "https://se-papc.sirat.bj:8001"

const STAGGING_URL = "http://137.255.9.235:8001"

const DEPLOYMENT_URL = "http://137.255.9.235:8001"

const PUSHER_BASE_URL = "137.255.9.235"

const PUSHER_APP_KEY = "ae75f3a73201ef37c668"

const PUSHER_APP_CLUSTER = "eu"

export  {API_BASE_URL , PUSHER_APP_CLUSTER, PUSHER_APP_KEY, PUSHER_BASE_URL}
