<template>
  <dashboard>
    <!-- <div class="w-full">
      <iframe class="w-full h-screen" src="https://www.kobotoolbox.org/" sandbox="" allow="sameorigin" frameborder="0"></iframe>
    </div> -->
  </dashboard>
</template>

<script>
  import Dashboard from '@/layouts/Dashboard'
export default {
  components: {
    Dashboard,
  },
  
}
</script>

<style>

</style>