export const FILL = "FILL";

export const SET_LIST = "SET_LIST";

export const ADD = "ADD";

export const MODIFY = "MODIFY";

export const FIND = "FIND";

export const REMOVE = "REMOVE";

export const RESET_STATE = "RESET";

export const FILTER_BY = "FILTER_BY";

export const SET_LOADER = "SET_LOADER";

export const SET_AUTH_DATA = "SET_AUTH_DATA";

export const SET_ACCESS_TOKEN = "SET_ACCESS_TOKEN";

export const RESET_AUTH_DATA = "RESET_AUTH_DATA";

export const SET_ERRORS_MESSAGE = "SET_ERRORS_MESSAGE";

export const RESET_ERROR_MESSAGE_MUTATIONS = "RESET_ERROR_MESSAGE_MUTATIONS";