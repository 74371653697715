<template>
  <div>
    <h1 class="py-2 text-2xl font-bold">Les differentes espaces </h1>
   <ul>
    <li> <router-link to="/bailleurs/dashboard" target="_blank">espace bailleurs</router-link> </li>
    <li> <router-link to="/mod/collecteur" target="_blank"> espace mod </router-link> </li>
    <li> <router-link to="/mo/mission-controle" target="_blank">espace mission de controle </router-link> </li>
    <li> <router-link to="/dashboard/bailleurs" target="_blank">espace unite de gestion</router-link> </li>
   </ul>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>