<template>
  <div>
    <span class=" inline-block text-xs font-bold sm:text-base uppercase  border-b-8  border-primary py-1">
      <slot></slot>
    </span>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>