
import getters from './getters'
import actions from './actions'
import mutations from './mutations'
import Indicateur from './indicateur'

const state = () => ({
  errors: [],
  indicateurs: {
    "currentPage" : null,
    "data" : [],
    "firstPageURL" : null,
    "from" : null,
    "lastPage" : null,
    "lastPageURL" : null,
    "links" : null,
    "nextPageURL" : null,
    "path" : null,
    "perPage" : null,
    "prevPage" : null,
    "from" : null,
    "total" : null
  },
  lists: [],
  indicateur : new Indicateur()
})

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
