<template>
  <dashboard>
    <div v-if="showModal">
      <modal-top-right :title="title" v-on:close="showModal = false">

        <div class="px-4 overflow-y-auto">
          <form action="" @submit.prevent="sendForm" class="p-0 w-full">

            <div class="my-4">
              <label class="text-xs font-semibold text-gray-500 uppercase block md:text-sm text-light"> Nom de
                l'activité <span class="px-2 text-xs sm:text-sm  md:text-lg font-black text-red-700">*</span></label>
              <input required v-model="nom"
                class="px-3 py-2 mt-1 border-2 border-gray-300 w-full focus:outline-none focus:ring-2  focus:border-transparent"
                type="text" placeholder="Entrer le nom de l'activite" />
            </div>

            <div class="my-4">
              <label class="text-xs font-semibold text-gray-500 uppercase block md:text-sm text-light"> Selectionnez une
                entreprise <span class="px-2 text-xs sm:text-sm  md:text-lg font-black text-red-700">*</span></label>
              <div class="flex justify-between my-3">
                <div>
                  <input type="radio" @click="allEntreprise= false" id="all" name="entreprise" value="all">
                  <label class="px-2" for="all">Tout les entreprises</label>
                </div>
                <div>
                  <input type="radio" @click="allEntreprise= true" id="sauf" name="entreprise" value="sauf">
                  <label class="px-2" for="sauf">Tout sauf</label>
                </div>
              </div>

              <multiselect v-if="allEntreprise" v-model="value" tag-placeholder="seleectionnez une entreprise"
                placeholder="selectionnez une entreprise" label="nom" track-by="nom"
                :selectLabel="'Appuyez sur Entrée pour sélectionner'"
                :deselectLabel="'Appuyez sur Entrée pour supprimer'" :options="entreprises" :multiple="true"
                :taggable="true" @tag="addTag"></multiselect>
            </div>

            <div class="my-4">
              <label class="text-xs font-semibold text-gray-500 uppercase block md:text-sm text-light">Date debut <span
                  class="px-2 text-xs sm:text-sm  md:text-lg font-black text-red-700">*</span></label>
              <input required v-model="debut"
                class="px-3 py-2 mt-1 border-2 border-gray-300 w-full focus:outline-none focus:ring-2  focus:border-transparent"
                type="date" placeholder="Entrer le nom du composante" />
            </div>

            <div class="my-4">
              <label class="text-xs font-semibold text-gray-500 uppercase block md:text-sm text-light">Date fin <span
                  class="px-2 text-xs sm:text-sm  md:text-lg font-black text-red-700">*</span></label>
              <input required v-model="fin"
                class="px-3 py-2 mt-1 border-2 border-gray-300 w-full focus:outline-none focus:ring-2  focus:border-transparent"
                type="date" placeholder="Entrer le nom du composante" />
            </div>


            <div class='flex items-center justify-center gap-4 pt-5 pb-5 my-4 md:gap-8'>
              <button type="submit"
                class='w-auto px-4 py-2 font-medium text-white  shadow-xl bg-blue-500 hover:opacity-80'>
                Enregistrer
              </button>

              <button type="" class='w-auto px-4 py-2 font-medium text-white  shadow-xl bg-red-500 hover:opacity-80'>
                Annuler
              </button>

            </div>
          </form>
        </div>
      </modal-top-right>
    </div>
    <vmodal v-if="showLink" @close="showLink=false">
      <div class="m-2 p-2 bg-white shadow" style="width:60vw">
        <p class="p-2 bg-gray-300 border text-gray-700 font-semibold text-sm border-gray-600 text-center rounded-md">
          <a title="cliquez ici" target="_blank" href="/form"> cliquer ici</a>
        </p>
      </div>
    </vmodal>
    <div class="w-full">
      <nav class="text-xs md:text-sm font-semibold flex flex-wrap justify-between items-center " aria-label="Breadcrumb">
        <div>
          <ol class="inline-flex p-0 list-none">
            <li class="flex items-center text-blue-500">
              <router-link to="" class="text-gray-600">dashboard</router-link>
              <svg class="w-3 h-3 mx-3 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                <path
                  d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z" />
              </svg>
            </li>
            <li class="flex items-center ">
              <router-link to="" class="text-gray-600">entreprise</router-link>
            </li>
          </ol>
        </div>
        <div>
          <search-bar></search-bar>
        </div>
      </nav>

      <div class="flex items-center justify-between my-2">
        <div class="flex space-x-4">
          <span @click="goEntreprise" v-if="entrepriseExecutanteVisible"
            :class="{'border-primary border-b-8':seeEntreprise}"
            class="inline-block cursor-pointer  text-lg uppercase font-bold    py-2"> Rapports entreprise </span>
          <span @click="goActivite" v-if="activiteEnvVisible" :class="{'border-primary border-b-8':seeActivite}"
            class="inline-block cursor-pointer  text-lg uppercase font-bold   py-2"> Les activites </span>
          <span @click="goFormulaire" v-if="formulaireVisible" :class="{'border-primary border-b-8':seeFormulaire}"
            class="inline-block cursor-pointer  text-lg uppercase font-bold   py-2"> Les formulaires </span>
        </div>
        <div>
          <button @click="addActivite" v-if="seeActivite && activiteEnvAdd" title="creer une activité"
            class="py-2 px-4 overflow-hidden flex space-x-2 items-center text-xs font-semibold text-white uppercase bg-primary focus:outline-none focus:shadow-outline">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
              style="fill: rgba(255, 255, 255, 1);transform: ;msFilter:;">
              <path d="M19 11h-6V5h-2v6H5v2h6v6h2v-6h6z"></path>
            </svg>
            <span class="mx-2 text-xs  md:text-sm font-semibold">Creer une activité </span>
          </button>
          <router-link v-if="seeFormulaire && formulaireAdd"
            :to="{name:'entreprise_checklist_create',params:{id:$route.params.id}}" title="ajouter une checklist"
            class="py-2 px-4 overflow-hidden flex space-x-2 items-center text-xs font-semibold text-white uppercase bg-primary focus:outline-none focus:shadow-outline">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
              style="fill: rgba(255, 255, 255, 1);transform: ;msFilter:;">
              <path d="M19 11h-6V5h-2v6H5v2h6v6h2v-6h6z"></path>
            </svg>
            <span class="mx-2 text-xs  md:text-sm font-semibold">Creer un formulaire </span>
          </router-link>
        </div>

      </div>

      <div v-if="seeEntreprise" class="grid grid-cols-1 my-4 lg:grid-cols-3 md:grid-cols-2 sm:gap-5">
        <div v-for="(entreprise,index) in filteredEntreprise()" :key="index">
          <div @click="gotoNext(entreprise.id)" class="relative cursor-pointer">

            <div class="relative p-6 min-w-full bg-white sm:rounded-lg overflow-hidden _shadow-md">
              
              <span
              class="relative text-lg sm:text-xl text-white tracking-tight font-bold bg-pink-500 px-4 py-2 rounded-lg uppercase flex no-wrap w-4/5 justify-start items-center"><i
                  class='bx bx-coin-stack bx-md'></i>{{entreprise.nom}}</span>

              <p class="my-2 text-normal text-sm sm:text-base font-medium text-gray-600 truncate">
                {{entreprise.user.nom}}</p>


              <div class="flex items-center mt-1 text-gray-600 dark:text-gray-400">
                <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"
                  viewBox="0 0 24 24" class="w-8 h-8 text-gray-500">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"
                    d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z" />
                </svg>
                <div class="ml-4 text-xs  md:text-md tracking-wide font-semibold w-full truncate">
                  {{entreprise.user.contact}}</div>
              </div>

              <div class="flex items-center mb-8 text-gray-600 dark:text-gray-400">
                <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"
                  viewBox="0 0 24 24" class="w-8 h-8 text-gray-500">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"
                    d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                </svg>
                <div class="ml-4 text-xs  md:text-md tracking-wide font-semibold w-full truncate">
                  {{entreprise.user.email}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="seeActivite">
        <vtable v-if="activiteEnvDelete && activiteEnvUpdate" :headers="headers" :datas="filteredEActivite()"
          :actions="actions" @supprimer="supprimerActivite" @updateInputData="updateInputData"></vtable>
        <vtable v-if="activiteEnvDelete && !activiteEnvUpdate" :headers="headers" :datas="filteredEActivite()"
          :actions="actions" @supprimer="supprimerActivite"></vtable>
        <vtable v-if="!activiteEnvDelete && activiteEnvUpdate" :headers="headers" :datas="filteredEActivite()"
          :actions="actions" @updateInputData="updateInputData"></vtable>
        <vtable v-if="!activiteEnvDelete && !activiteEnvUpdate && activiteEnvVisible" :headers="headers"
          :datas="filteredEActivite()"></vtable>
      </div>
      <div v-if="seeFormulaire">
        <div class="grid grid-cols-3 gap-4">
          <div v-for="formulaire in filteredFormulaire()" :key="formulaire.id">
            <card-checklist :libelle=formulaire.nom :activite=formulaire.auteur @supprimer="supprimer"
              @gotoDetails="gotoDetails(formulaire.id)" @onlink="getlink(formulaire.id)" :isdelete="formulaireDelete"
              :isUpdate="formulaireUpdate" :isGotoLink="suiviEnvAdd">
            </card-checklist>
          </div>
        </div>
      </div>
    </div>
  </dashboard>
</template>

<script>
import ModalTopRight from '@/components/ModalTopRight'
import SearchBar from '@/components/SearchBar'
import CardBailleur from '@/components/CardBailleur'
import Titre from '@/components/Titre'
import Vtable from '@/components/Vtable'
import Vform from '@/components/Vform'
import Vmodal from '@/components/Vmodal'
import Dashboard from '@/layouts/Dashboard'
import Dropdown from '@/components/Dropdown.vue'
import CardChecklist from '@/components/CardChecklist'
import Multiselect from 'vue-multiselect'
import { mapGetters, mapMutations, mapActions, mapState } from "vuex";

export default {

  components: {
    Vtable,
    Dashboard,
    Vmodal,
    Vform,
    CardBailleur,
    Titre,
    SearchBar,
    ModalTopRight,
    Dropdown,
    CardChecklist,
    Multiselect,

  },
  data() {
    return {
      title: '',
      showModal: false,
      allEntreprise: false,
      champs: [],

      showLink: false,
      headers: [
        { name: 'Libelle activite', cle: 'nom' },
        { name: 'code', cle: 'code' },
        { name: 'Entreprise', cle: 'entreprise' },
        { name: 'Date debut', cle: 'debut' },
        { name: 'Date fin', cle: 'fin' },
        { name: 'Date de creation', cle: 'created_at' },
      ],
      search: '',

      actions: [
        { name: 'supprimer', iconne: '' },
      ],

      option: [
        { name: 'supprimer' },
        { name: 'modifier' },
        { name: 'continuer', iconne: '' },
      ],
      seeEntreprise: true,
      seeFormulaire: false,
      seeActivite: false,
      value: [],
      entrepriseExecutanteVisible: false,
      entrepriseExecutanteAdd: false,
      activiteEnvVisible: false,
      activiteEnvAdd: false,
      activiteEnvDelete: false,
      activiteEnvUpdate: false,
      formulaireVisible: false,
      formulaireAdd: false,
      formulaireDelete: false,
      formulaireUpdate: false,
      suiviEnvAdd: false,
    }
  },
  computed: {
    ...mapState({
      identifiant: state => state.auths.identifiant,
      eActivite: state => state.eActivites.eActivite,
      formulaire: state => state.formulaires.formulaire,
      loading: state => state.loading,
      errors: state => state.errors,
      statuts: state => state.statuts
    }),

    ...mapGetters({
      hasErrors: "GET_ERREURS",
      isLoading: "IS_LOADING",
      entreprises: "entreprisesExecutante/getEntreprisesExecutante",
      eActivites: "eActivites/getEActivites",
      formulaires: "formulaires/getFormulaires",
      currentUser: "auths/GET_AUTHENTICATE_USER",
    }),
    ...mapGetters('auths', { currentUser: 'GET_AUTHENTICATE_USER' })
  },
  mounted() {
    this.getPermission()
    if (!this.activiteEnvVisible || !this.entrepriseExecutanteVisible || !this.formulaireVisible) {
      this.$router.push('/401-non-autorise')
    }
    console.log("Fetching entreprises executantes ...");
    this.fetchEntreprisesExecutante();

    console.log("Fetching e-activites ...")
    this.fetchEActivites(this.currentUser?.programme?.id)

    console.log("Fetching list formulaires ...")
    this.fetchFormulaires()

  },
  methods: {
    //Charger les fonctions de communication avec le serveur
    getPermission() {
      this.currentUser.role[0].permissions.forEach(element => {
        if (element.slug === 'voir-un-suivi-environnementale') {
          this.entrepriseExecutanteVisible = true
        }
        if (element.slug === 'creer-une-activite-environnementale') {
          this.activiteEnvAdd = true
        }
        if (element.slug === 'modifier-une-activite-environnementale') {
          this.activiteEnvUpdate = true
        }
        if (element.slug === 'supprimer-une-activite-environnementale') {
          this.activiteEnvDelete = true
        }
        if (element.slug === 'voir-une-activite-environnementale') {
          this.activiteEnvVisible = true
        }
        if (element.slug === 'voir-un-formulaire') {
          this.formulaireVisible = true
        }
        if (element.slug === 'creer-un-formulaire') {
          this.formulaireAdd = true
        }
        if (element.slug === 'modifier-un-formulaire') {
          this.formulaireUpdate = true
        }
        if (element.slug === 'supprimer-un-formulaire') {
          this.formulaireUpdate = true
        }
        if (element.slug === 'creer-un-suivi-environnementale') {
          this.suiviEnvAdd = true
        }

      });
    },

    ...mapMutations({
      setErrors: 'SET_ERRORS_MESSAGE', // map `this.setErrors()` to `this.$store.commit('SET_ERRORS_MESSAGE')`,
      setEActivite: 'eActivites/FILL', // map `this.CREATE_INSTANCE_PROJET()` to `this.$store.commit('CREATE_INSTANCE_PROJET')`
      setFormulaire: 'formulaires/FILL' // map `this.CREATE_INSTANCE_PROJET()` to `this.$store.commit('CREATE_INSTANCE_PROJET')`
    }),

    ...mapActions("eActivites", {
      fetchEActivites: "FETCH_LIST_EACTIVITE",
      saveEActivite: "STORE_EACTIVITE",
      updateEActivite: "UPDATE_EACTIVITE",
      deleteEActivite: "DESTROY_EACTIVITE"
    }),

    ...mapActions("formulaires", {
      fetchFormulaires: "FETCH_LIST_FORMULAIRE",
      saveFormulaire: "STORE_FORMULAIRE",
      updateFormulaire: "UPDATE_FORMULAIRE",
      deleteFormulaire: "DESTROY_FORMULAIRE"
    }),


    ...mapActions("entreprisesExecutante", {
      fetchEntreprisesExecutante: "FETCH_LIST_ENTREPRISE_EXECUTANTE",
    }),

    filteredEntreprise() {
      var self = this;

      return this.entreprises.filter(function (entreprise) {
        return entreprise.user.email.toLowerCase().indexOf(self.search.toLowerCase()) >= 0 ||
          entreprise.user.nom.toLowerCase().indexOf(self.search.toLowerCase()) >= 0;
      });
    },

    filteredEActivite() {
      var self = this;

      return this.eActivites.filter(function (eActivtie) {
        return eActivtie.nom.toLowerCase().indexOf(self.search.toLowerCase()) >= 0 ||
          eActivtie.entreprise.toLowerCase().indexOf(self.search.toLowerCase()) >= 0 ||
          eActivtie.code.toLowerCase().indexOf(self.search.toLowerCase()) >= 0;
      });
    },

    filteredFormulaire() {
      var self = this;

      return this.formulaires.filter(function (formulaire) {
        return formulaire.nom.toLowerCase().indexOf(self.search.toLowerCase()) >= 0 ||
          formulaire.auteur.toLowerCase().indexOf(self.search.toLowerCase()) >= 0;
      });
    },

    addTag(newTag) {
      const tag = {
        nom: newTag,
      }
      this.options.push(tag)
      this.value.push(tag)
    },
    getlink(i) {
      /*if(this.suiviEnvAdd) {
        this.formulaireId = i
        this.showLink = true
      }*/

      this.$router.push({ name: 'form_gestion_generator', params: { id: i } })

    },
    gotoDetails(id) {
      this.$router.push({ name: 'entreprise_checklist_details', params: { idE: id } })
    },
    selectData({ name }) {
      return `${name}`
    },
    close() {
      this.showCloseModal();

      this.resetForm();
    },
    addActivite() {
      this.title = 'Creer'
      this.showModal = true
    },

    async updateInputData(id, attribut, value) {

      await this.updateEActivite({ eActivite: { [attribut]: value }, id: id }).catch((value, status) => {
        if (this.hasErrors[attribut] !== undefined)
          alert(this.hasErrors[attribut])
      }).finally(() => {
        $('#' + "dataTable").load(location.href + '#' + id + '>*', '')
      })

    },

    sendForm() {

      var isIn = false
      var entrepriseExecutantId = []

      if (this.allEntreprise) {
        this.entreprises.forEach(entreprise => {
          this.value.forEach(element => {
            if (entreprise === element) {
              isIn = true
            }
          });

          if (isIn == false) {
            entrepriseExecutantId.push(entreprise.id)
          }
        });
      }

      else {
        this.entreprises.forEach(element => {
          entrepriseExecutantId.push(element.id)
        });
      }

      let eActivite = {
        "nom": this.nom,
        "debut": this.debut,
        "fin": this.fin,
        "code": this.code,
        "statut": this.statut,
        "entrepriseExecutantId": entrepriseExecutantId
      }

      console.log(eActivite)

      this.saveEActivite(eActivite).then(response => {
        if (response.status == 200 || response.status == 201) {
          this.close()
        }
      })

    },

    async supprimerActivite(activite) {
      if (window.confirm("Voulez-vous supprimer cette activité " + activite.nom))
        await this.deleteEActivite(activite.id)
    },

    goEntreprise() {
      this.seeEntreprise = true
      this.seeFormulaire = false
      this.seeActivite = false
    },
    goFormulaire() {
      this.seeEntreprise = false
      this.seeFormulaire = true
      this.seeActivite = false
    },
    goActivite() {
      this.seeEntreprise = false
      this.seeFormulaire = false
      this.seeActivite = true
    },

    gotoNext(id) {
      this.$router.push({ name: 'entreprise_checklist', params: { idE: id } })
    },

  },
  watch: {
    isLoading: function (value) {
      //this.loading = value
    },

    hasErrors: function (errors) {
      this.champs.forEach((value) => {
        value.errors = errors[value.key];
      });
      //this.errors = errors
    },

    entreprises: function (entreprises) {
      this.entreprises.map((item) => {
        item.nom = item.user.nom
        return item;

      });
    }
  }
}
</script>

<style>

</style>